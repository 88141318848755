<template>
  <div class="article-page">
    <div v-if="pageContent !== null && pageContent?.[0]?.type !== 'BlockTextForm'" class="wrapper">
      <CBreadcrumbs
        v-if="breadcrumbs"
        :parent-sections="breadcrumbs"
        :current-page-name="pageHeader"
      />
      <h1 v-if="pageHeader">
        {{ pageHeader }}
      </h1>
    </div>
    <h1
      v-else
      :class="{
        'hidden-header': pageContent !== null && pageContent?.[0]?.type === 'BlockTextForm',
      }"
    >
      {{ pageHeader }}
    </h1>
    <template v-for="(block, index) in pageContent" :key="block.id">
      <Component
        v-bind="getBlocksProps(block, index)"
        :is="articleBlocks[block.type]"
        v-if="articleBlocks[block.type]"
        :class="{ 'mt-0--i': block.type === 'BlockTextForm' }"
        :breadcrumbs="breadcrumbs"
        :page-header="pageHeader"
        class="article-block"
        :is-article="template === 'page-detail'"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  createError,
  ref,
  useAsyncData,
  useHead,
  useRouter,
  useRuntimeConfig,
  useSeoMeta,
} from '#imports';
import type { ClientBreadcrumbsItem } from '~/types/client/pages';
import { type ApiPageExtendedResponse, type PageBlocks, type PageTemplate } from '~/types/pages';
import CBreadcrumbs from '~/components/breadcrumbs/CBreadcrumbs.vue';
import { useArticleBlocks } from '~/composables/useArticleBlocks';
import './styles.scss';
import type { ErrorResponse } from '~/server/utils/serverFetch';
import { isErrorResponse } from '~/server/utils/serverError';
import type { PropType } from '@vue/runtime-core';

const props = defineProps({
  template: {
    type: String as PropType<PageTemplate>,
  },
});

const emit = defineEmits(['error-not-found']);

const pageContent = ref<PageBlocks[]>();
const pageHeader = ref<string>();
const breadcrumbs = ref<ClientBreadcrumbsItem[]>();
const route = useRouter().currentRoute.value;

const { articleBlocks, getBlocksProps } = useArticleBlocks();
const { siteUrl } = useRuntimeConfig().public;

const slug = computed(() => {
  if (route.path === '/') {
    return 'index';
  }

  if (route.params && route.params.chapters) {
    return (route.params.chapters as string[]).join('/');
  }
});

const endpoint = computed(() =>
  props.template === 'page-detail' ? '/api/pages/extended' : '/api/services/extended',
);

const { data, error } = await useAsyncData<ErrorResponse | ApiPageExtendedResponse>(
  'getDetailContent',
  () => {
    return $fetch(endpoint.value, {
      method: 'GET',
      params: {
        slug: slug.value,
      },
    });
  },
);

if (error.value) {
  throw createError(error.value);
}

if (data.value) {
  if (!isErrorResponse(data.value)) {
    pageContent.value = data.value.blocks;

    pageHeader.value = data.value.seo.h1;

    if (props.template === 'page-detail') {
      breadcrumbs.value = data.value.breadcrumbs;
    } else {
      const transformBreadcrumbs = data.value.breadcrumbs.map(el => ({
        id: el.id,
        slug: el.full_slug,
        title: el.title,
      }));

      breadcrumbs.value = [
        {
          id: 0,
          slug: '/services/',
          title: 'Услуги',
        },
        ...transformBreadcrumbs,
      ];
    }

    useHead({
      title: data.value.seo.title || data.value.title,
    });

    useSeoMeta({
      description: data.value.seo.description.replace(/[""'`']/g, ''),
      keywords: data.value.seo.keywords.replace(/[""'`']/g, ''),
      ogTitle: data.value.og.title.replace(/[""'`']/g, ''),
      ogDescription: data.value.og.description.replace(/[""'`']/g, ''),
      ogImage: data.value?.og.image.source
        ? `${siteUrl}${data.value.og.image.source}`
        : '/android-chrome-192x192.png',
      ogUrl: route.fullPath,
    });
  } else {
    emit('error-not-found');
  }
}
</script>
